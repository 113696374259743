import { getApi, ConnectAdminApi } from "../../../utils/api-utils";

export async function getContractors(queryStringParameters) {
  const result = await getApi(ConnectAdminApi.CONTRACTOR_API, `/get-filtered-contractors`, { queryStringParameters });
  return result.allUserList;
}

export async function getContractorDetails(queryStringParameters) {
  const result = await getApi(ConnectAdminApi.CONTRACTOR_API, `/get-contractor-details`, { queryStringParameters });
  return result;
}

export const getEmailLogs = async(fromTime, toTime, selectedOrg=undefined) => {
  try {
    const fromDate = fromTime.clone().utc().startOf('minute').format("YYYY-MM-DDTHH:mm:ss");
    const toDate = toTime.clone().utc().endOf('minute').format("YYYY-MM-DDTHH:mm:ss");
    const queryStringParameters = {selectedOrg, fromDate, toDate};
    const result = await getApi(ConnectAdminApi.CONTRACTOR_API, `/get-email-logs`, { queryStringParameters });
    return result;
  } catch(error) {
    console.log('Error', error);
    return error;
  }
}