import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Button, Tag, Tooltip } from 'antd';
import { ORG_STATUS, USER_STATUS, USER_TYPE } from './constants';

export class ContractorListItem {
    static get Builder() {
        class Builder {
            constructor(user, orgList) {
                this.user = user;
                this.orgList = orgList
            }
            build() {
                const user = this.user;
                const orgList = this.orgList;
                if(user.org_id === ORG_STATUS.NOT_ASSIGNED) {
                    user.orgName = 'Not Assigned yet';
                } else {
                    const selectedOrgData = orgList.find(org => org.org_id === user.org_id);
                    user.orgName = selectedOrgData.name;
                }
                const userName = (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : user.contractor_name;
                const tagText =  user.isAdmin === USER_TYPE.ADMIN ? 'Admin':'User';
                
                const compliancetagColor = user.compliance === 0 ? 'green' : 'red';
                const compliancetagText = user.compliance === 0 ? 'Compliant' : 'Not Compliant';
                
                let builder = new ListItem.Builder();
                const id = user.isAdmin === USER_TYPE.ADMIN ? user.contractor_id : user.user_id;
                builder = builder
                    .setTitleWithLink(`${userName}`, `/contractors/contractor_details/${id}/${user.org_id}/${user.isAdmin}`)
                    .setDescription(
                        <div>
                            {user.contractor_name ? (<Row>{`Business Name: ${user.contractor_name}`}</Row>) : null}
                            <Row>{`Email: ${user.email || user.contact_email}`}</Row>
                            <Row>{`Organisation: ${user.orgName}`}</Row>
                        </div>
                    )
                    .setRightIcon(<span><Tag style={{width: '50px', textAlign:'center'}} color='green'>{tagText}</Tag> <Tag color={compliancetagColor}>{compliancetagText}</Tag></span>)      
                return builder.build();
            }
        }
        return Builder;
    }
}
