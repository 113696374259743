import React, { Component } from "react";
import { getOrgList, setBreadcrumb } from '../../../actions/commonActions';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { Form, Input, Icon, Button, message, Modal, DatePicker, TimePicker, Row, Col, Table } from "antd";
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';
import { ListWidget } from "../../../components/ListWidget";
import { ContractorListItem } from "../ContractorListItemBuilder";
import { getContractors, getEmailLogs } from "../actions/contractorActions";
import { setSelectedMenu } from '../../../actions/commonActions';
import moment from 'moment';
import { CSVLink } from "react-csv";

class ContractorListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            selectedOrg: null,
            searchedOrg: undefined,
            loading: false,
            users: [],
            exportModalView: false,
            fromDate: moment(),
            fromTime: moment('00:00', 'HH:mm'),
            toDate: moment(),
            toTime: moment('23:59', 'HH:mm'),
            dataSource: [],
            csvData: [],
            loading: false,
        };

        this.props.setBreadcrumb("Contractors", [
            { route: '', label: 'Contractors' }
        ]);
    }

    componentDidMount = () => {
        this.props.getOrgList();
    }

    filterOrgs = () => {
        return SearchUtils.filter(this.props.orgList ? this.props.orgList : [], "org_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const orgs = this.filterOrgs();
        const optionsMap = { title: "Organisations", children: [] };
        orgs.forEach((org) => {
            optionsMap.children.push({ title: org.name, id: org.org_id });
        });

        return [optionsMap];
    }

    onOrgSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.orgIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    getUsersForListView = () => {
        const orgList = this.props.orgList
        let userList = this.state.users.map((user) => {
            let builder = new ContractorListItem.Builder(user, orgList);
            return builder.build();
        });
        return userList;
    }

    onSearchSelect = (orgId) => {
        this.setState({ selectedOrg: orgId })
    }

    onOrgSelect = (orgId) => {
        this.setState({ searchedOrg: orgId})
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const { businessName, email, firstName, lastName, abn } = values;
            const { selectedOrg } = this.state;
            if (!businessName && !email && !firstName && !lastName && !selectedOrg && !abn) {
                message.error("Please enter at least one search criteria")
                return;
            }
            if (selectedOrg) {
                values.selectedOrg = selectedOrg;
            }
            this.setState({ loading: true });
            const users = await getContractors(values);
            
            this.setState({ loading: false, users });
        });
    }

    onReset = () => {
        this.props.form.resetFields();
        this.setState({ users: [], selectedOrg: null });
    }

    onExport = () => {
        this.setState({ exportModalView: true });
    }

    onGenerate = async() => {
        const {searchedOrg, toDate, toTime, fromDate, fromTime} = this.state;
        if(fromDate > toDate) {
            message.warning('Please check the selected time range and try again!');
            return
        } else {
            const orgList = this.props.orgList
            this.setState({ loading: true});
            fromDate.hour(fromTime.hour()).minute(fromTime.minute()).second(0);
            toDate.hour(toTime.hour()).minute(toTime.minute()).second(0);
            let {emailLogs} = await getEmailLogs(fromDate, toDate, searchedOrg);
            if(emailLogs && emailLogs.length) {
                emailLogs = emailLogs.sort((a,b) => moment(a.CreatedAt) - moment(b.CreatedAt));
                for(let i=0; i<emailLogs.length; i++) {
                    const organization = orgList.find(org => org.org_id === emailLogs[i].OrgId);
                    const metadata = JSON.parse(emailLogs[i].MetaData);
                    emailLogs[i].UserName = metadata.UserName;
                    emailLogs[i].CreatedAt = moment(emailLogs[i].CreatedAt).format('YYYY-MM-DD HH:mm:ss');
                    emailLogs[i].SentBy = metadata.SentBy === "SYSTEM" ? "System Generated" : "Connect admin";
                    emailLogs[i].OrgName = organization ? organization.name : 'Not Assigned';
                }
            }
            this.setState({dataSource: emailLogs, loading: false})
        }
    }

    prepareCsvData = () => {
        const {fromDate, toDate} = this.state;
        const reportTimePeriod = `${fromDate.format('DD/MM/YYYY HH:mm')} to ${toDate.format('DD/MM/YYYY HH:mm')}`
        const csv = [['Email Logs'], [reportTimePeriod], []];
        csv.push(this.columns.map(col => col.title));
        this.state.dataSource.forEach(row => {
            const dataRow = this.columns.map(({ key }) => row[key]);
            csv.push(dataRow);
        });
        this.setState({ csvData: csv });
    }

    onCloseModal = () => {
        this.setState({
            exportModalView: false,
            dataSource: [],
            fromDate: moment(),
            fromTime: moment('00:00', 'HH:mm'),
            toDate: moment(),
            toTime: moment('23:59', 'HH:mm'),
            searchedOrg: undefined
        })
    }

    columns = [
        {
            title: 'Sent By',
            dataIndex: 'SentBy',
            key: 'SentBy',
        },
        {
            title: 'Date & Time',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt'
        },
        {
            title: 'Sent To',
            dataIndex: 'RecipientMail',
            key: 'RecipientMail',
        },
        {
            title: 'Contractor Email',
            dataIndex: 'UserName',
            key: 'UserName',
        },
        {
            title: 'Organisation',
            dataIndex: 'OrgName',
            key: 'OrgName',
        }
    ];

    disableDates = (current) => {
        const sixMonthsAgo = moment().subtract(6, "month").startOf("day");
        const today = moment().startOf("day");
        return current && (current < sixMonthsAgo || current > today.endOf("day"));
      };

    onOrgChange = (value) => {
        if(!value) {
            this.setState({searchedOrg : undefined})
        }
    }

    onContractorOrgChange = (value) => {
        if(!value) {
            this.setState({selectedOrg : null})
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <Form layout="inline" onSubmit={this.onSearch}>
                    <Form.Item>
                        {getFieldDecorator('businessName',
                        )(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Business Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email',
                        )(
                            <Input
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="email"
                                placeholder="Email"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('firstName',
                        )(
                            <Input
                                prefix="F"
                                placeholder="First Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('lastName',
                        )(
                            <Input
                                prefix="L"
                                placeholder="Last Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('abn',
                        )(
                            <Input
                                prefix="A"
                                placeholder="ABN"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('selectedOrg',
                        )(
                            <Search
                                searchText={"Select Organisation"}
                                handleSearch={(value) => this.onOrgSearch(value)}
                                handleSelect={(value) => this.onSearchSelect(value)}
                                options={this.filterSearchOptions()}
                                onChange = {(value) =>this.onContractorOrgChange(value)}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Search
                            </Button>
                        <Button style={{ marginLeft: '10px' }} type="primary" onClick={this.onReset} >
                            Reset
                        </Button>
                        <Button style={{ marginLeft: '10px' }} type="primary" onClick={this.onExport} >
                            Export Email Logs
                        </Button>
                    </Form.Item>
                </Form>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getUsersForListView()} loading={this.state.loading} />
                    </div>
                </div>
                <Modal
                    visible={this.state.exportModalView}
                    onOk={() => this.onCloseModal()}
                    onCancel={() => this.onCloseModal()}
                    title="Export Email Logs"
                    okText="Ok"
                    width={'90%'}
                >
                    <div>
                        <div className="pure-g epar__content__taskbar">
                            <div className="pure-u-1" style={{ marginTop: 5 }}>
                                <div className="__date_control">
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col md={12}>
                                            <Col md={2}> From:</Col>
                                            <Col md={8}>
                                                <DatePicker
                                                    disabledDate={this.disableDates}
                                                    className='mr-4 mt-2 ml-2'
                                                    onChange={fromDate => this.setState({ fromDate })}
                                                    format="DD/MM/YYYY"
                                                    value={this.state.fromDate}
                                                    allowClear={false}
                                                /> 
                                            </Col>
                                            <Col md={10}>
                                                <TimePicker
                                                    className='mr-4 mt-2'
                                                    onChange={fromTime => this.setState({ fromTime })}
                                                    format="h:mm a"
                                                    value={this.state.fromTime}
                                                    allowClear={false}
                                                /> 
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col md={12}>
                                            <Col md={2}> To:</Col>
                                            <Col md={8}>
                                                <DatePicker
                                                    disabledDate={this.disableDates}
                                                    className='mr-4 mt-2 ml-2'
                                                    onChange={toDate => this.setState({ toDate })}
                                                    format="DD/MM/YYYY"
                                                    value={this.state.toDate}
                                                    allowClear={false}
                                                /> 
                                            </Col>
                                            <Col md={10}>
                                                <TimePicker
                                                    className='mr-4 mt-2'
                                                    onChange={toTime => this.setState({ toTime })}
                                                    format="h:mm a"
                                                    value={this.state.toTime}
                                                    allowClear={false}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5} style={{paddingRight:'11px'}}>
                                            <Search
                                                searchText={"Select Organisation"}
                                                handleSearch={(value) => this.onOrgSearch(value)}
                                                handleSelect={(value) => this.onOrgSelect(value)}
                                                options={this.filterSearchOptions()}
                                                onChange = {(value) =>this.onOrgChange(value)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Button className='mt-2' onClick={this.onGenerate}>
                                                Generate
                                            </Button>
                                        </Col>
                                        <Col md={2}>
                                        {this.state.dataSource.length > 0 &&
                                            <CSVLink
                                                data={this.state.csvData}
                                                filename={"Contractor-Report-Export.csv"}
                                                target="_blank"
                                                onClick={(event, done) => {
                                                    this.prepareCsvData();
                                                }}
                                            >
                                                <Button className="mt-2 ml-3">
                                                    Export Data
                                                </Button>
                                            </CSVLink>
                                        }
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <Table dataSource={this.state.dataSource} columns={this.columns} loading={this.state.loading}/>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        orgIndex: state.getIn(['orgState', 'orgIndex']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch),
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ContractorListView)));